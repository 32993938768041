//统一管理项目的全部的接口
import requests from "./request";


// 资讯列表
export const reqCategory = (language) => requests({
    url: '/system/info/newsList',
    method: 'get',
    params:language
});

export const reqProjectCategory = (language) => requests({
    url: '/system/projectinfo/newsList',
    method: 'get',
    params:language
});

//添加登记信息
export const reqPostList = (data) => requests({
    url: "/system/register/info/add",
    method: 'post',
    data: data
})


//城市图片
export const imgList = (query) => requests({
    url: "/system/images/imagesList",
    method: "get",
    params: query
})

//banner图列表
export const bannersList = (query) => requests({
    url: `/system/banner/getBanner`,
    method: "get",
    params: query
})


// 详情

export const lists = (id) => requests({
    url: `/system/info/getById/${id}`,
    method: "get"
})

