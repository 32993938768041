<template>
  <div id="app">
    <router-view :Urllist="Urllist" v-if="isReq"/>
  </div>
</template>
<script>
import DevicePixelRatio from "@/utils/evicePixelRatio.js";
import { bannersList } from "@/api";
export default {
  data() {
    return {
      Urllist: [],
      isReq:false,
    };
  },
  mounted() {
    this.$router.afterEach((to, form, next) => {
      window.scrollTo(0, 0);
    });
  },
  methods: {},
  created() {
    new DevicePixelRatio().init();
    let banners = 0;
    bannersList({language: "CN" }).then((res) => {
      this.Urllist = res.rows;
      this.isReq = true
      console.log(this.Urllist, 999);
    });
  },
  methods: {},
};
</script>

<style>
</style>
