import axios from 'axios';
let requests = axios.create({
    baseURL: '/prod-api',
    timeout: 50000
});
requests.interceptors.request.use((config) => {
    return config;
});
requests.interceptors.response.use((res) => {
    return res.data;
}, (error) => {
    return Promise.reject(error);
});
//对外暴露
export default requests;