import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: '/menay',
    name:"Menay",
    component: ()=>import("@/views/Menay/menay.vue")
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home/home.vue"),
  },
  {
    path: "/innovation",
    name: "Innovation",
    component: () => import("@/views/Innovation/innovation.vue"),
  },
  {
    path: "/sharp",
    name: "Sharp",
    component: () => import("@/views/Sharp/sharp.vue"),
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("@/views/Service/service.vue"),
  },
  {
    path: "/zhengzhou",
    name: "Zhengzhou",
    component: () => import("@/views/Zhengzhou/zhengzhou.vue"),
  },
  {
    path: "/sun",
    name: "Sun",
    component: () => import("@/views/Sun/sun.vue"),
  },
  {
    path: "/zhuhai",
    name: "Zhuhai",
    component: () => import("@/views/Zhuhai/zhuhai.vue"),
  },
  {
    path: "/xuwen",
    name: "Xuwen",
    component: () => import("@/views/Xuwen/xuwen.vue"),
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("@/views/Message/message.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/News/news.vue"),
  },
  {
    path: "/project",
    name: "Project",
    component: () => import("@/views/Project/project.vue"),
  },
  {
    path: "/foreign",
    name: "Foreign",
    component: () => import("@/views/Foreign/foreign.vue"),
  },
  {
    path: "/SwissCham",
    name: "swissCham",
    component: () => import("@/views/SwissCham/swissCham.vue"),
  },
  {
    path: "/details",
    name: "Details",
    component: () => import("@/views/Details/details.vue"),
  },
  {
    path: "/xuchang",
    name: "Xuchang",
    component: () => import("@/views/Xuchang/xuchang.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base:"/CN/",
  routes,
})

export default router
