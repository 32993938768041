<template>
  <div>
    <!-- footer -->
    <div class="footer">
      <div class="left el-row--flex"
           style="border-right: 1px solid  rgb(128,128,128,0.3); padding-right: 10px;padding-left: 10px;">
        <div style="width:65%;float:left;">
          <img src="../../assets/images/logo_foot.png" alt=""/>
          <p>
            公司名称：北京中元智汇信息咨询有限公司 <br/>
            联系地址：北京市丰台区南四环西路汉威国际广场三区1号楼6层 <br/>
            联系方式：010-83678272 <br/>
            电子邮件：business@share-ssic.com
          </p>
        </div>
        <div style="width: 30%;">
          <img src="../../assets/images/ewm.png" alt=""
               style="    margin-left: 50px; width: 130px;    margin-top: 55%;"/>
        </div>
      </div>
      <div class="right el-row--flex" style="margin-top: 120px;">
        <div class="one-half" style="">
          <p class="bottomp"> <a href="https://www.swisscham.org/" target="_blank" style="color:white;"> 中国瑞士商会</a>
          </p>
        </div>
        <div class="one-half ">
          <p class="bottomp">
            <a href="http://www.sccc.ch/" target="_blank" style="color:white;"> SCCC瑞中商会</a>
          </p>
        </div>
        <div class="one-half last-column">
          <p>
            <a href="https://swissnex.org/china/zh/" target="_blank" style="color:white;"> 瑞士联邦政府科技文化中心</a>
          </p>
        </div>
        <div class="one-half ">
          <p class="bottomp">
            <a href="https://www.bencham.org" target="_blank" style="color:white;"> 中国荷比卢商会</a>
          </p>
        </div>
        <div class="one-half">
          <p class="bottomp">
            <a href="https://www.cameraitacina.com/cn" target="_blank" style="color:white;"> 中国意大利商会</a>
          </p>
        </div>
        <div class="one-half last-column">
          <p>
            <a href="https://www.bcecc.be/" target="_blank" style="color:white;"> 比中经贸委员会</a>
          </p>
        </div>
        <div class="one-half">
          <p class="bottomp">
            <a href="http://waws.cc/a/guanyuwomen/qiyegaikuang/" target="_blank" style="color:white;"> 世界智慧科学院</a>
          </p>
        </div>
        <div class="one-half ">
          <p class="bottomp">
            北欧致公协会
          </p>
        </div>
        <div class="one-half last-column">
          <p>
            瑞士办事处
          </p>
        </div>
        <div class="one-half ">
          <p class="bottomp">
           芬兰办事处
          </p>
        </div>
        <div class="one-half last-column">
          <p>
           各国办事处（筹备中）
          </p>
        </div>
      </div>

      <i></i>
      <div class="copyright">Copyright 2022 - All right reserved  <a href="https://beian.miit.gov.cn/#/Integrated/index" style="font-size: 12px;color:white;" target="_blank">京ICP备2023018846号</a>
      </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  height: 600px;
  // background: #00032b;
  background: url(~@/assets/images/footer_bg.png) no-repeat;
  background-size: cover;
  padding: 0px 130px 0px;
  align-items: center;
  border-bottom: 2px solid #ffffff;
  position: relative;
  text-align: left;

  .left {
    width: 50%;

    img {
      width: 300px;
    }

    p {
      font-size: 17px;
      font-family: AlibabaPuHuiTi-Light, AlibabaPuHuiTi;
      font-weight: 300;
      color: #ffffff;
      line-height: 50px;
      opacity: 0.8;
      margin-top: 50px;
    }

    span {
      display: block;
      font-size: 16px;
      font-family: AppleColorEmoji;
      color: #ffffff;
      // opacity: 0.4;
      margin-top: 117px;
    }
  }

  .right {
    width: 50%;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: block;
    //display: flex;
    //flex-direction: column;
    //justify-content: left;
    position: relative;
    top: -70px;
    left: 50px;
    align-items: center;

    p {
      padding-top: 5px;
      font-size: 14px;
      color: #ffffff;
      opacity: 0.8;
    }

    img {
      background: #383946;
      // margin: 22px 57px 0px;
      width: 200px;
      height: 200px;
    }
  }

  i {
    position: absolute;
    left: 0px;
    top: 440px;
    width: 1683px;
    height: 2px;
    opacity: 0.2;
    // border: 1px solid #ffffff;
    box-sizing: border-box;
    margin: 0px 118px;
  }
}

.one-half {
  width: 30%;
  float: left;
  margin-right: 5%;
}

.last-column {
  margin-right: 0% !important;
  padding-bottom: 10px;
}

.bottomp {
  border-right: 1px solid gray;
  padding-right: 10px;
  padding-left: 10px;
}

.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  font-family: AppleColorEmoji;
  font-size: 21px;
  line-height: 50px;
}
</style>
