<template>
  <div>
    <div class="nav-bar" id="blackdiv">
      <img src="../../assets/images/logo2.png" alt=""/>
      <el-menu
          router
          :default-active="activeIndex2"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="transparent"
          text-color="#fff"
          active-text-color="#fff"
          style="overflow: hidden"
      >
        <el-submenu index="1">
          <template #title>
            <span style="color: black!important;">首页</span>
          </template>
          <el-menu-item index="/home" style="color: black"
          >首页
          </el-menu-item
          >
          <el-menu-item index="/service" style="color: black!important;"
          >企业服务
          </el-menu-item
          >
        </el-submenu>
        <el-submenu index="2">
          <template #title>
            <span style="color: black!important;">关于我们</span>
          </template>
          <el-menu-item index="/innovation" style="color: black"
          >中瑞创新中心介绍
          </el-menu-item
          >
          <el-menu-item index="/Sharp" style="color: black!important;"
          >思瑞介绍
          </el-menu-item
          >
          <el-menu-item index="/SwissCham" style="color: black!important;"
          >中国瑞士商会
          </el-menu-item
          >
        </el-submenu>
        <!--        <el-submenu index="3">
                  <template #title>
                    <span style="color: black!important;">企业服务</span>
                  </template>
                  <el-menu-item index="/service" style="color: black"
                  >中资企业服务
                  </el-menu-item
                  >
                  <el-menu-item index="/foreign" style="color: black"
                  >外资企业服务
                  </el-menu-item
                  >
                </el-submenu>-->
        <el-menu-item index="/project" style="color: black">项目推荐</el-menu-item>
        <el-submenu index="4">
          <template #title>
            <span style="color: black!important;">落地城市</span>
          </template>
          <el-menu-item index="/sun" style="color: black">日照</el-menu-item>
          <el-menu-item index="/Zhengzhou" style="color: black"
          >郑州
          </el-menu-item
          >
          <el-menu-item index="/xuchang" style="color: black"
          >许昌
          </el-menu-item
          >
          <el-menu-item index="/zhuhai" style="color: black"
          >珠海
          </el-menu-item
          >
          <el-menu-item index="/xuwen" style="color: black"
          >徐闻
          </el-menu-item
          >
        </el-submenu>

        <el-menu-item index="/news" style="color: black">新闻动态</el-menu-item>
        <el-menu-item index="/menay" style="color: black">中阿创新</el-menu-item>
        <el-menu-item index="/message" style="color: black"
        >联系我们
        </el-menu-item
        >
      </el-menu>
      <div style="display: flex;">
        <div>
          <span class="zhongwen">中文</span>
        </div>
        <div style="margin-left: 20px;"><a href="http://www.share-ssic.com/EN/home"><span class="yingwen">英文</span></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DevicePixelRatio from "@/utils/evicePixelRatio.js"

export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
  },
  mounted() {
    new DevicePixelRatio().init()
  },
};
</script>
<style lang="less" scoped>
.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid #ffffff;
  border-bottom: 1px solid #d8d8d87a;
  box-sizing: border-box;
  margin: 0px 130px;
  position: relative;

  .js {
    position: relative;

    .bgs-list {
      width: 276px;
      height: 100%;
      background: #00276b;
      opacity: 0.6;
      position: absolute;
      left: 0px;
      bottom: -128px;

      .a-href {
        text-decoration: none;
        color: white;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: center;
      top: center;
      // background: url(../../assets/images/xiala.png) no-repeat;
      width: 25px;
      height: 25px;
    }
  }

  img {
    width: 316px;
    height: 52px;
    position: relative;
  }

  .list {
    list-style: none;
    display: flex;
    position: relative;
    justify-content: space-between;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      border-bottom: 4px solid #ffffff;
    }

    li {
      font-size: 21px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      padding: 50px;
      margin-left: 20px;
    }
  }

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
<style lang="less">
.el-submenu__title *{
  vertical-align: baseline !important;
}
.el-menu--popup {
  z-index: 100;
  min-width: 140px !important;
  border: none;
  padding: 5px 0;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.wrap .bgc .el-submenu__title {
  height: 100px;
  line-height: 100px;
  color: black !important;
}

.el-menu-item .is-active {
  color: #ffffff !important;
}

.el-submenu__title:hover {
  // background: #0f4888 !important;
  color: #ffffff !important;
}

.el-menu-item:hover {
  background-color: #0f4888 !important;
}

.container .bgc .el-submenu__title {
  height: 100px;
  line-height: 100px;
  color: black !important;
}

.el-submenu__title:hover {
  color: #ffffff !important;
  background: #0f4888 !important;
}

.zhongwen {
  font-size: 14px;
  color: black !important;;
}

.zhongwen:before {
  content: "●";
  margin-right: 10px;
  color: #FF3030;
}

.yingwen {
  font-size: 14px;
  color: black !important;
}

.yingwen:before {
  content: "●";
  margin-right: 10px;
  color: #271BC1;
}

#blackdiv .el-menu .el-submenu__icon-arrow {
  color: black !important;
}

</style>

