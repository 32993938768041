import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入swiper样式，因为程序很多组件都需要用到这个样式，咱们只是引入一次即可！！！
import "swiper/css/swiper.min.css"
Vue.config.productionTip = false
import Header from '@/components/Header/header.vue'
Vue.component("Header", Header)
import Footer from "@/components/Footer/footer.vue"
Vue.component("Footer", Footer)
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';

import 'lib-flexible/flexible.js'


import * as API from '@/api';
Vue.prototype.$ajax = API
// import VueLazyLoad from 'vue-lazyload'
// Vue.use(VueLazyLoad);
Vue.filter("dateFilter", function (original) {
  var dt = new Date(original)
  var y = dt.getFullYear()
  var m = dt.getMonth()
  var d = dt.getDate()

  var h = dt.getHours()
  var f = dt.getMinutes()
  var s = dt.getSeconds()

  return `${y}/${m}/${d}`
})

import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'jXEGw4DYpgEENtVVP69PS5OpzqkF33UR'
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')